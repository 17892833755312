import { Pipe, PipeTransform, Optional } from '@angular/core';
import { WEIGHT_MAP, WeightCategory, WeightMapItem } from '../models/weight-category';

export type WeightCategoryUnitsOutput = 'kg' | 'lbs' | 'both' | 'userPrefered';
export type WeightCategoryOutputType = 'onlyLabel' | 'onlyValue' | 'valueWithUnits' | 'full';

@Pipe({
  name: 'chWeightCategory',
})
export class WeightCategoryPipe implements PipeTransform {

  constructor(
    // @Optional() private _userDataService?: UserDataProvider
  ) {
  }

  // TODO: userPrefered
  /**
   * chWeightCategory => 70kg/155lbs Lightweight
   * chWeightCategory:'full' => 70kg/155lbs Lightweight
   * chWeightCategory:'onlyValue' => 70/155
   * chWeightCategory:'valueWithUnits' => 70kg/155lbs
   * chWeightCategory:'onlyLabel' => Lightweight
   *
   * chWeightCategory:'full':'kg' => 70kg Lightweight
   * chWeightCategory:'full':'lbs' => 155lbs Lightweight
   * chWeightCategory:'full':'both' => 70kg/155lbs Lightweight
   *
   * chWeightCategory:'onlyValue':'kg' => 70
   * chWeightCategory:'valueWithUnits':'kg' => 70kg
   * chWeightCategory:'onlyLabel':'kg' => Lightweight
   */
  transform(
    value: WeightCategory | string,
    outputType: WeightCategoryOutputType = 'full',
    units: WeightCategoryUnitsOutput = 'both'
  ) {
    const weightMap: WeightMapItem | undefined = WEIGHT_MAP[value];

    // check if this weight category exists
    if (!weightMap) {
      return 'Unknown';
    }

    if (outputType === 'onlyLabel') {
      return weightMap.label;
    }

    let outputStr = '';

    // value part
    if (units === 'both') {
      outputStr = `${Math.round(weightMap.kg)}${outputType === 'onlyValue' ? '' : 'kg'}`;
      outputStr += `/${Math.round(weightMap.lbs)}${outputType === 'onlyValue' ? '' : 'lbs'}`;
    } else {
      // if (units === 'userPrefered' && this._userDataService && this._userDataService.currentData) {
      //   units = this._userDataService.currentData.units === 'imperial' ? 'lbs' : 'kg';
      // }
      outputStr = `${Math.round(weightMap[units])}${outputType === 'onlyValue' ? '' : units}`;
    }

    if (outputType === 'full') {
      outputStr += ` ${weightMap.label}`;
    }

    return outputStr;
  }
}

