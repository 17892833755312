import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of as observableOf, merge, from as rxjsFrom } from 'rxjs';
import { Fighter, FighterFilter } from '../../../cagehunter-core/models/fighter';
import { CagehunterFighterService } from '../../../cagehunter-core/services/cagehunter-fighter.service';

/**
 * Data source for the FighterList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class FighterTableDataSource extends DataSource<Fighter> {
  data: Fighter[] = [];
  currentFilter: FighterFilter = {};

  constructor(
    private paginator: MatPaginator,
    private sort: MatSort,
    private filterObservable: Observable<FighterFilter>,
    private _fighterService: CagehunterFighterService
  ) {
    super();
    this.filterObservable.subscribe((val) => this.currentFilter = val);
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange,
      this.filterObservable
    ];

    // Set the paginators length
    this.paginator.length = this.data.length;

    return merge(...dataMutations).pipe(
      switchMap(() => {
        return rxjsFrom(this._fetchData());
      }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  private async _fetchData() {
    const page = this.paginator.pageIndex;
    const limit = this.paginator.pageSize;
    const fightersResult = await this._fighterService.getAllByFilter(this.currentFilter, page, limit);

    this.paginator.length = fightersResult.totalCount;

    return fightersResult.data;
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  // private getPagedData(data: FighterListItem[]) {
  //   const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
  //   return data.splice(startIndex, this.paginator.pageSize);
  // }

  // /**
  //  * Sort the data (client-side). If you're using server-side sorting,
  //  * this would be replaced by requesting the appropriate data from the server.
  //  */
  // private getSortedData(data: FighterListItem[]) {
  //   if (!this.sort.active || this.sort.direction === '') {
  //     return data;
  //   }

  //   return data.sort((a, b) => {
  //     const isAsc = this.sort.direction === 'asc';
  //     switch (this.sort.active) {
  //       case 'name': return compare(a.name, b.name, isAsc);
  //       case 'id': return compare(+a.id, +b.id, isAsc);
  //       default: return 0;
  //     }
  //   });
  // }

}
