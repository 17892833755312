import { WeightCategory } from './weight-category';
import { Gender } from './gender';
import { Background } from './background';
import { ImageObject } from './image';
import { UserStatus } from './user';


export interface FighterFilter {
  search?: string;
  managerId?: string;
  gender?: Gender;
  weightCategory?: WeightCategory;
  minFights?: number;
  maxFights?: number;
  background?: string;
  countryCode?: string;
  association?: string;
  hideWithoutSherdogProfile?: boolean;
}

export enum FighterStatus {
  ACTIVE = 'active',
  DELETED = 'deleted'
}

export interface UrlFighterData {
  fighterId: string;
  createdAt: number;
}

export interface BaseFighter {
  /** General data about fighter */
  firstName?: string;
  lastName?: string;
  nickName?: string;
  gender?: Gender;
  birthday?: number | Date | null;

  images?: ImageObject;

  fightTitle?: string;
  association?: string;
  country?: string;
  countryCode?: string;
  state?: string;
  city?: string;
  latitude?: number;
  longitude?: number;

  /** in centimeters */
  height?: number;
  weightCategory?: WeightCategory;
  score?: {
    wins?: number;
    losses?: number;
    draws?: number;
    total?: number;
  };
  background?: Background;

  /**
   * Url to Sherdog represented as URL after sherdog.com. e.g. "/fighter/Galamirza-Aivazov-221721"
   */
  sherdogUrl?: string;
  /**
   * Date of last update score from sherdog. Used only if sherdogUrl exist.
   * Score is updated by firebase function every XY days.
   */
  sherdogUpdatedAt?: number | Date | Object | null;

  /**
   * User ID of fighter`s manager.
   */
  managerId?: string;

  /**
   * Represents user ID of fighter (fighter profesion).
   * Value is presented only if manager added login to fighter. (shadow fighter)
   */
  userId?: string;

  createdAt?: number | Date | Object;
  updatedAt?: number | Date | Object;

  /**
   * Auto generated values using firebase functions
   */
  numNewOffers?: number;
  numOffers?: number;

  /**
   * Is this fighter locked for manager? In case manager do not pay and we locked this fighter.
   */
  lockForManager?: boolean;

  // this is userStatus of manager (owner of this fighter)!
  userStatus?: UserStatus;
  status?: FighterStatus;
}

export interface FighterDbData extends BaseFighter {
  birthday?: number | null;

  sherdogUpdatedAt?: number | Object;

  createdAt?: number | Object;
  updatedAt?: number | Object;
}

export interface Fighter extends BaseFighter {
  id: string;

  birthday?: Date | null;

  sherdogUpdatedAt?: Date | null;

  createdAt?: Date;
  updatedAt?: Date;
}
