import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort } from '@angular/material';
import { map, switchMap } from 'rxjs/operators';

import { Observable, of as observableOf, merge, from as rxjsFrom } from 'rxjs';
import { OfferFilter, Offer } from 'src/app/cagehunter-core/models/offer';
import { CagehunterOfferService } from 'src/app/cagehunter-core/services/cagehunter-offer.service';

/**
 * Data source for the FighterList view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class OfferTableDataSource extends DataSource<any> {
  data: Offer[] = [];
  filterData: OfferFilter = {};

  constructor(
    private paginator: MatPaginator,
    private sort: MatSort,
    private filterDataObservable: Observable<OfferFilter>,
    private _offerService: CagehunterOfferService
  ) {
    super();
    this.filterDataObservable.subscribe(filterData => this.filterData = filterData);
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<any[]> {
    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange,
      this.filterDataObservable
    ];

    // Set the paginators length
    this.paginator.length = this.data.length;

    return merge(...dataMutations).pipe(
      switchMap(() => {
        return rxjsFrom(this._fetchData());
      }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() { }

  private async _fetchData() {
    const page = this.paginator.pageIndex;
    const limit = this.paginator.pageSize;
    const offersResult = await this._offerService.getAllByFilter(this.filterData, page, limit);

    this.paginator.length = offersResult.totalCount;

    return offersResult.data;
  }

}
