import { Gender } from './gender';
import { WeightCategory } from './weight-category';

export interface OfferFilter {
  search?: string;
  gender?: Gender;
  weightCategory?: WeightCategory;
  ownerId?: string;
}

/**
 * Enum for offer status.
 */
export enum OfferStatus {
  /** Offer is created but not released - visible only for creator */
  DRAFT = 'draft',
  /** Offer is active/released and requests are sent to fighters */
  ACTIVE = 'active',
  // TODO: ??? wtf?
  RESERVED = 'reserved',
  /** Offer is deleted - requests/responses are possible sent and could be visible for fighters/managers */
  DELETED = 'deleted',
  // TODO: ??? wtf?
  CLOSED = 'closed'
}

/** Represents maximum fights possible in maxFights criteria */
export const MAX_FIGHTS = 999;
/** Represents maximum radius possible in maxRadius criteria */
export const MAX_RADIUS = 999999999;

// TODO: move to mobile client?
export interface RadiusOption {
  min: number;
  max: number;
}
// TODO: move to mobile client?
export const RADIUS_OPTIONS: RadiusOption[] = [
  { min: 0, max: 200 },
  { min: 0, max: 500 },
  { min: 0, max: 1000 },
  { min: 0, max: 3000 },
  { min: 3000, max: MAX_RADIUS },
];

export interface Offer {
  id?: string | null;

  eventName: string;
  eventDate: Date;
  oponent?: string;
  moneyDescription?: string;
  description?: string;

  // location
  latitude?: number;
  longitude?: number;
  country?: string;
  countryCode?: string;
  state?: string;
  city?: string;
  zipCode?: string;
  streetName?: string;
  streetNumber?: string;
  locationName?: string;

  // criterias
  gender: Gender;
  weightCategory: WeightCategory;
  minFights: number;
  maxFights: number;
  minRadius: number;
  maxRadius: number;

  // if fighterId is set, status have to be reserved
  status: OfferStatus;
  // this prop is set only by fighter id reserved for
  fighterId?: string;
  // approved
  approvedByEmail?: string;
  approvedAt?: Date;

  ownerId?: string;
  createdAt?: Date;
  updatedAt?: Date;

  // auto generated
  numNewResponses?: number;
  numResponses?: number;
}
