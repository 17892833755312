// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  type: 'dev',
  firebase: {
    apiKey: 'AIzaSyCDfdeDhdoDIteR38tOrm-OZ0S2Dte-8Us',
    authDomain: 'cagehunterdev.firebaseapp.com',
    databaseURL: 'https://cagehunterdev.firebaseio.com',
    projectId: 'cagehunterdev',
    storageBucket: 'cagehunterdev.appspot.com',
    messagingSenderId: '312357924954'
  },
  algolia: {
    apiKey: '41cddffd77e36a1d35aae2ccdcb1d14b',
    aplicationID: 'SOUCFJ2ZKL'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
