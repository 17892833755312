export enum Gender {
  MALE = 'male',
  FEMALE = 'female'
}

export const GENDERS = [
  Gender.MALE,
  Gender.FEMALE
];


