import { Pipe, PipeTransform, Optional } from '@angular/core';

export type LengthUnitOutputType = 'imperial' | 'metric' | 'both' | 'userPrefered';
export type LenghtUnitInputUnit = 'cm' | 'km';

@Pipe({
  name: 'chLengthUnit',
})
export class LengthUnitPipe implements PipeTransform {

  constructor() { }

  transform(input: string | number, inputUnit: LenghtUnitInputUnit = 'cm', outputType: LengthUnitOutputType = 'metric'): string {
    input = Number(input);
    const metricOutput: string = input.toFixed(0) + inputUnit;
    let imperialOutput: string;
    // handle imperial output
    if (inputUnit === 'cm') {
      const ftIn = this.cmToFtIn(input);
      imperialOutput = ftIn.ft.toFixed(0) + 'ft ' + ftIn.in.toFixed(0) + 'in';
    } else {
      imperialOutput = this.kmToMi(input) + 'mi';
    }

    if (outputType === 'userPrefered') {
      // to handle only imperial || metric input
      // output = (this._userDataService.currentData && this._userDataService.currentData.units === 'imperial') ? 'imperial' : 'metric';
    }

    // handle metric output
    if (outputType === 'both') {
      return metricOutput + '/' + imperialOutput;
    } else if (outputType === 'imperial') {
      return imperialOutput;
    } else {
      return metricOutput;
    }
  }

  private cmToFtIn(cm: number): { ft: number, in: number } {
    // TODO: is this operation safe?
    let inches = Number((cm * 0.393700787).toFixed(0));
    const feet = Math.floor(inches / 12);
    inches %= 12;

    return {
      ft: Number(feet.toFixed(0)),
      in: Number(inches.toFixed(0))
    };
  }

  private kmToMi(km: number): number {
    return Number((km * 0.62137).toFixed(0));
  }
}

