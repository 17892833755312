import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';
import { AdminPermission } from './cagehunter-core/models/user';

const routes: Routes = [
  { path: '', redirectTo: '/fighters', pathMatch: 'full' },
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'fighters',
        loadChildren: './fighters/fighters.module#FightersModule'
      },
      {
        path: 'offers',
        loadChildren: './offers/offers.module#OffersModule'
      },
      {
        path: 'users',
        loadChildren: './users/users.module#UsersModule'
      },
      {
        path: 'settings',
        loadChildren: './settings/settings.module#SettingsModule',
        data: {
          permission: AdminPermission.SuperAdmin
        }
      },
      {
        path: 'scripts',
        loadChildren: './scripts/scripts.module#ScriptsModule',
        data: {
          permission: AdminPermission.RunScripts
        }
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
