import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CagehunterAuthAdminService } from '../../../cagehunter-core/services/cagehunter-auth-admin.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  @ViewChild('loginForm') loginForm: NgForm;

  email = '';
  password = '';
  loading = false;

  constructor(
    private _dialogRef: MatDialogRef<LoginDialogComponent>,
    private _adminAuthService: CagehunterAuthAdminService,
    private _toastrService: ToastrService,
    private _router: Router
  ) { }

  ngOnInit() {
  }

  async submitLoginForm() {
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    try {
      await this._adminAuthService.signIn(this.email, this.password);
      this._dialogRef.close();
      this._router.navigate(['']);
    } catch (err) {
      this._toastrService.error(err.message ? err.message : err);
    }
    this.loading = false;
  }
}
