import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, tap } from 'rxjs/operators';
import { MatDialog, MatDrawer } from '@angular/material';


import { LoginDialogComponent } from './shared/dialogs/login-dialog/login-dialog.component';
import { environment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { CagehunterAuthAdminService } from './cagehunter-core/services/cagehunter-auth-admin.service';
import { AdminUser, AdminPermission } from './cagehunter-core/models/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('drawer') drawer: MatDrawer;

  loggedAdminUser: Observable<AdminUser | undefined>;
  isProduction: boolean = environment.production;
  environmentType: string = environment.type;

  // permissions
  isSuperAdmin = false;
  canRunScripts = false;

  private _isXLarge = false;
  isXLarge$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.XLarge])
    .pipe(
      map(result => result.matches),
      tap(matched => this._isXLarge = matched)
    );

  constructor(
    private _adminAuthService: CagehunterAuthAdminService,
    private _breakpointObserver: BreakpointObserver,
    private _dialog: MatDialog,
    private _router: Router
  ) {
    this.loggedAdminUser = this._adminAuthService.loggedAdminUser;

    this.loadPermissionVariables();

    this.loggedAdminUser.subscribe(user => {
      if (!user) {
        this._openLoginDialog();
      } else {
        // need to reload permissions because new user is logged in
        this.loadPermissionVariables();
      }
    });

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && !this._isXLarge) {
        this.drawer.close();
      }
    });

  }

  loadPermissionVariables() {
    this.isSuperAdmin = this._adminAuthService.hasPermission(AdminPermission.SuperAdmin);
    this.canRunScripts = this._adminAuthService.hasPermission(AdminPermission.RunScripts);
  }

  logout() {
    this._adminAuthService.signOut();
  }

  private _openLoginDialog() {
    const dialogRef = this._dialog.open(LoginDialogComponent, {
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
