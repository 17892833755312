import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { take, map } from 'rxjs/operators';

import { AdminUser, AdminPermission, AdminData } from '../models/user';


@Injectable({
  providedIn: 'root'
})
export class CagehunterAuthAdminService {

  private _initialized = false;

  /**
   * Represents state of auth and current logged user.
   */
  private _loggedAdminUser: BehaviorSubject<AdminUser | undefined> = new BehaviorSubject<AdminUser | undefined>(undefined);
  loggedAdminUser: Observable<AdminUser | undefined> = this._loggedAdminUser.asObservable();
  get currentLoggedAdminUser(): AdminUser | undefined {
    return this._loggedAdminUser.getValue();
  }

  get isAuthenticated() {
    return !!this._loggedAdminUser.getValue();
  }

  constructor(
    private _afAuth: AngularFireAuth,
    private _afDatabase: AngularFireDatabase
  ) { }

  /**
   * Initialize this service from firebase auth
   */
  async init(): Promise<void> {
    if (!this._initialized) {
      try {
        const alreadyLoggedInUser = await this._afAuth.authState.pipe(
          take(1),
        ).toPromise();

        if (alreadyLoggedInUser) {
          await this._initAdminUser(alreadyLoggedInUser);
          return;
        }
      } catch (err) {
        // handle all errors becase this method is called in APP_INITIALIZER and throw errors will cause stop app before load all components
        console.log(err.message ? err.message : err);
        // logout current user - handle cases user was changed between login
        this.signOut();
      }
    }
    return;
  }

  /**
   *
   */
  async signIn(email: string, password: string): Promise<AdminUser> {
    // sign in over firebase
    const userCredential = await this._afAuth.auth.signInWithEmailAndPassword(email, password);
    const adminUser = await this._initAdminUser(userCredential.user);

    return adminUser;
  }

  /**
   *
   */
  async signOut(): Promise<void> {
    await this._afAuth.auth.signOut();
    this._loggedAdminUser.next(undefined);
    return;
  }

  hasPermission(permission: AdminPermission): boolean {
    const currAdminUser = this.currentLoggedAdminUser;

    if (currAdminUser) {
      return currAdminUser.permissions.indexOf(permission) >= 0 || currAdminUser.permissions.indexOf(AdminPermission.SuperAdmin) >= 0;
    }
    return false;
  }

  /**
   * Init AdminUser from DB and emit logged user.
   */
  private async _initAdminUser(user: firebase.User | null) {
    // firebase typing - possible nulls
    if (!user || !user.email) {
      throw new Error('firebase - signInWithEmailAndPassword - uncorrect user data from firebase');
    }
    // find admin in DB with permissions
    const adminDataSnapshot = await this._afDatabase.object<AdminData>(`/admins/${user.uid}`).snapshotChanges().pipe(
      take(1),
      map(action => action.payload)
    ).toPromise();
    const adminData = adminDataSnapshot.val();

    if (!adminDataSnapshot.exists() || !adminData) {
      throw new Error('This user is not an admin!');
    }

    const permissions: AdminPermission[] = <AdminPermission[]>adminData.permissions.split(',');
    const adminUser: AdminUser = {
      email: user.email,
      permissions
    };
    this._loggedAdminUser.next(adminUser);

    return adminUser;
  }
}
