import { Pipe, PipeTransform } from '@angular/core';
import { Background, BACKGROUND_MAP } from '../models/background';


@Pipe({
  name: 'chBackground',
})
export class BackgroundPipe implements PipeTransform {

  transform(value: Background) {
    return BACKGROUND_MAP[value] || 'Unknown';
  }
}
