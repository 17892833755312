import { NgModule, ModuleWithProviders, InjectionToken, APP_INITIALIZER } from '@angular/core';
import * as algoliasearch from 'algoliasearch';
import { FirebaseOptions, AngularFireModule, FirebaseOptionsToken } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MomentModule } from 'ngx-moment';

import { CagehunterAuthAdminService } from './services/cagehunter-auth-admin.service';
import { AgeFromBirthdayPipe } from './pipes/age-from-birthday.pipe';
import { BackgroundPipe } from './pipes/background.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { LengthUnitPipe } from './pipes/length-unit.pipe';
import { WeightCategoryPipe } from './pipes/weight-category.pipe';

export const AlgoliaOptionsToken = new InjectionToken<AlgoliaConfig>('cagehunter.algolia.options');
export const AlgoliaClient = new InjectionToken<algoliasearch.Client>('algolia.client');

// because of no default export and rollup error we have to assign constructor function to variable
// https://github.com/rollup/rollup/issues/670#issuecomment-281139978
// https://github.com/jvandemo/generator-angular2-library/issues/221
// https://github.com/rollup/rollup/issues/1267
const algoliasearchConstructor = algoliasearch;

export interface AlgoliaConfig {
  aplicationID: string;
  apiKey: string;
}

export interface CageHunterConfig {
  firebase: FirebaseOptions;
  algolia: AlgoliaConfig;
}

export function _algoliaClientFactory(config: AlgoliaConfig) {
  return algoliasearchConstructor(config.aplicationID, config.apiKey, { protocol: 'https:' });
}

export function _appInitFactory(adminAuth: CagehunterAuthAdminService) {
  const promises: Promise<any>[] = [
    adminAuth.init()
  ];
  return () => Promise.all(promises);
}

const PIPES = [
  AgeFromBirthdayPipe,
  BackgroundPipe,
  GenderPipe,
  LengthUnitPipe,
  WeightCategoryPipe
];


@NgModule({
  imports: [
    AngularFireModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    MomentModule
  ],
  declarations: [
    ...PIPES
  ],
  exports: [
    ...PIPES
  ]
})
export class CagehunterModule {

  static forRoot(config: CageHunterConfig): ModuleWithProviders {
    return {
      ngModule: CagehunterModule,
      providers: [
        // provide pipes as services - available to inject over DI
        ...PIPES,
        // provide FirebaseOptionToken instead call AngularFireModule.initializeApp()
        { provide: FirebaseOptionsToken, useValue: config.firebase },
        { provide: AlgoliaOptionsToken, useValue: config.algolia },
        {
          provide: AlgoliaClient,
          useFactory: _algoliaClientFactory,
          deps: [AlgoliaOptionsToken]
        },
        {
          provide: APP_INITIALIZER,
          useFactory: _appInitFactory,
          multi: true,
          deps: [CagehunterAuthAdminService]
        }
      ]
    };
  }

}
