import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '../models/gender';


@Pipe({
  name: 'chGender',
})
export class GenderPipe implements PipeTransform {

  transform(value: Gender | string, onlyFirstLetter?: boolean): string {
    if (!(<any>Object).values(Gender).includes(value)) {
      value = 'Unknown';
    }

    if (onlyFirstLetter) {
      return value.charAt(0).toUpperCase();
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
