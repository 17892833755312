import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FighterFilter, Fighter, FighterStatus } from '../../../cagehunter-core/models/fighter';
import { CagehunterFighterService } from '../../../cagehunter-core/services/cagehunter-fighter.service';
import { FighterTableDataSource } from './fighter-table-datasource';
import { UserStatus } from 'src/app/cagehunter-core/models/user';

@Component({
  selector: 'app-fighter-table',
  templateUrl: './fighter-table.component.html',
  styleUrls: ['./fighter-table.component.scss']
})
export class FighterTableComponent implements OnInit {

  @Input() filterObservable: Observable<FighterFilter>;
  @Input() enableDelete = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: FighterTableDataSource;

  UserStatus = UserStatus;
  FighterStatus = FighterStatus;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'name', 'gender', 'age', 'score', 'weightCategory', 'country', 'association', 'sherdog',
    'visibility', 'shadowFighter', 'created', 'actions'
  ];

  constructor(
    private _fighterService: CagehunterFighterService,
    private _toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.dataSource = new FighterTableDataSource(this.paginator, this.sort, this.filterObservable, this._fighterService);
  }

  async deleteFighter(row: Fighter) {
    await this._fighterService.delete(row.id);
    this._toastrService.success('Status updated');
    row.status = FighterStatus.DELETED;
  }

  async activateFighter(row: Fighter) {
    await this._fighterService.activate(row.id);
    this._toastrService.success('Status updated');
    row.status = FighterStatus.ACTIVE;
  }

}
