import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { ThemePalette } from '@angular/material';
import { OfferStatus } from '../../cagehunter-core/models/offer';

@Component({
  selector: 'app-offer-status-badge',
  template: `
    <mat-chip-list>
      <mat-chip [color]="color" [selected]="!isDeleted">{{ status }}</mat-chip>
    </mat-chip-list>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferStatusBadgeComponent implements OnInit, OnChanges {

  @Input() status: OfferStatus;

  color: ThemePalette;
  isDeleted = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status) {
      if (this.status === OfferStatus.ACTIVE) {
        this.color = 'primary';
      } else if (this.status === OfferStatus.CLOSED) {
        this.color = 'warn';
      }
      this.isDeleted = this.status === OfferStatus.DELETED;
    }
  }

}
