import { Injectable } from '@angular/core';
import { AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { take, map } from 'rxjs/operators';
import * as algoliasearch from 'algoliasearch';

import { CagehunterAlgoliaService } from './cagehunter-algolia.service';
import { Offer, OfferFilter, OfferStatus } from '../models/offer';
import { ListResult } from './cagehunter-fighter.service';

@Injectable({
  providedIn: 'root'
})
export class CagehunterOfferService {

  constructor(
    private _algoliaService: CagehunterAlgoliaService,
    private _afDatabase: AngularFireDatabase
  ) {
  }

  private _convertDbObjToOffer(snapshot: DatabaseSnapshot<any>) {
    const snapshotValue = snapshot.val();
    const offer: Offer = <Offer>Object.assign({}, snapshotValue);
    // skip fill id if exist - algolia
    if (!offer.id) {
      offer.id = snapshot.key;
    }
    offer.createdAt = new Date(snapshotValue.createdAt);
    offer.updatedAt = new Date(snapshotValue.updatedAt);
    offer.eventDate = new Date(snapshotValue.eventDate);

    return offer;
  }

  private _convertOfferToDbObj(offer: Offer): any {
    const obj: any = Object.assign({}, offer);
    if (offer.createdAt) {
      obj.createdAt = offer.createdAt.getTime();
    } else {
      obj.createdAt = firebase.database.ServerValue.TIMESTAMP;
    }
    // always use current timestamp
    obj.updatedAt = firebase.database.ServerValue.TIMESTAMP;
    if (obj.eventDate) {
      obj.eventDate = offer.eventDate.getTime();
    }
    delete obj.$key;

    return obj;
  }

  private _buildAlgoliaQuery(filterData: OfferFilter): string {
    const arr = [];
    if (filterData.gender) {
      arr.push(`gender:${filterData.gender}`);
    }
    if (filterData.weightCategory) {
      arr.push(`weightCategory:${filterData.weightCategory}`);
    }
    if (filterData.ownerId) {
      arr.push(`ownerId:${filterData.ownerId}`);
    }

    return arr.join(' AND ');
  }

  async getAllByFilter(filterData: OfferFilter = {}, page: number = 0, limit: number = 50): Promise<ListResult<Offer>> {
    const algoliaParams: algoliasearch.QueryParameters = {
      filters: this._buildAlgoliaQuery(filterData),
      hitsPerPage: limit,
      page
    };
    if (filterData.search) {
      algoliaParams.query = filterData.search;
    }
    this._algoliaService.offersIndex.clearCache();
    const response = await this._algoliaService.offersIndex.search(algoliaParams);
    return {
      data: response.hits,
      totalCount: response.nbHits
    };
  }

  async get(offerId: string): Promise<Offer> {
    return this._afDatabase.object(`/offers/${offerId}`).snapshotChanges().pipe(
      take(1),
      map((action) => this._convertDbObjToOffer(action.payload))
    ).toPromise();
  }

  async update(offer: Offer) {
    return this._afDatabase.object(`/offers/${offer.id}`).update(this._convertOfferToDbObj(offer));
  }

  async close(offerId: string) {
    return this._afDatabase.object(`/offers/${offerId}/status`).set(OfferStatus.CLOSED);
  }

  async approve(offerId: string, userEmail: string) {
    await this._afDatabase.object(`/offers/${offerId}/approvedByEmail`).set(userEmail);
    await this._afDatabase.object(`/offers/${offerId}/approvedAt`).set(new Date().getTime());
  }

}
