import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { environment } from '../../environments/environment';
import { CagehunterModule } from '../cagehunter-core/cagehunter.module';

@NgModule({
  imports: [
    CommonModule,
    CagehunterModule.forRoot({ firebase: environment.firebase, algolia: environment.algolia }),
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
