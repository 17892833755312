import { Injectable, Inject } from '@angular/core';
import { AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/database';

import { CagehunterAlgoliaService } from './cagehunter-algolia.service';
import { Fighter, FighterDbData, FighterFilter, UrlFighterData, FighterStatus } from '../models/fighter';
import * as firebase from 'firebase/app';
import * as algoliasearch from 'algoliasearch';
import { take, map, switchMap } from 'rxjs/operators';
import { Observable, from, of } from 'rxjs';

export interface ListResult<T> {
  data: T[];
  totalCount: number;
}


@Injectable({
  providedIn: 'root'
})
export class CagehunterFighterService {

  constructor(
    private _algoliaService: CagehunterAlgoliaService,
    private _afDatabase: AngularFireDatabase
  ) {
  }

  private _convertDbSnapshotToFighter(snapshot: DatabaseSnapshot<FighterDbData>): Fighter | undefined {
    const fighterDbData = snapshot.val();
    if (!fighterDbData || !snapshot.key) {
      return undefined;
    }

    const fighter: Fighter = <Fighter>Object.assign({}, fighterDbData);
    fighter.id = snapshot.key;
    fighter.birthday = typeof fighterDbData.birthday === 'number' ? new Date(fighterDbData.birthday) : null;
    fighter.createdAt = typeof fighterDbData.createdAt === 'number' ? new Date(fighterDbData.createdAt) : new Date();
    fighter.updatedAt = typeof fighterDbData.updatedAt === 'number' ? new Date(fighterDbData.updatedAt) : new Date();
    fighter.sherdogUpdatedAt = typeof fighterDbData.sherdogUpdatedAt === 'number' ? new Date(fighterDbData.sherdogUpdatedAt) : null;

    return fighter;
  }

  private _convertFighterToDbData(fighter: Fighter): FighterDbData {
    const fighterCopy: Fighter = Object.assign({}, fighter);

    // delete id previously added by _convertDbObjToFighter
    delete fighterCopy.id;
    // do not save images - generated by firebase functions
    delete fighterCopy.images;

    // change type
    const dbData = <FighterDbData>fighterCopy;

    // convert dates from Date to number
    dbData.birthday = fighter.birthday ? fighter.birthday.getTime() : null;
    dbData.createdAt = fighter.createdAt ? fighter.createdAt.getTime() : firebase.database.ServerValue.TIMESTAMP;
    // always use current timestamp
    dbData.updatedAt = firebase.database.ServerValue.TIMESTAMP;
    if (fighter.sherdogUrl && fighter.sherdogUpdatedAt) {
      dbData.sherdogUpdatedAt = fighter.sherdogUpdatedAt ? fighter.sherdogUpdatedAt.getTime() : firebase.database.ServerValue.TIMESTAMP;
    }
    // sum score
    if (dbData.score) {
      dbData.score.total = (dbData.score.wins || 0) + (dbData.score.losses || 0) + (dbData.score.draws || 0);
    }

    return dbData;
  }

  private _buildAlgoliaQuery(filter: FighterFilter): string {
    const arr = [];
    if (filter.managerId) {
      arr.push(`managerId:${filter.managerId}`);
    }
    if (filter.gender) {
      arr.push(`gender:${filter.gender}`);
    }
    if (filter.weightCategory) {
      arr.push(`weightCategory:${filter.weightCategory}`);
    }
    if (filter.minFights) {
      arr.push(`score.total >= ${filter.minFights}`);
    }
    if (filter.maxFights) {
      arr.push(`score.total <= ${filter.maxFights}`);
    }
    if (filter.countryCode && filter.countryCode !== '') {
      arr.push(`countryCode:${filter.countryCode}`);
    }
    if (filter.association && filter.association !== '') {
      arr.push(`association:"${filter.association}"`);
    }
    if (filter.background && filter.background !== '') {
      arr.push(`background:${filter.background}`);
    }
    if (filter.hideWithoutSherdogProfile) {
      arr.push(`sherdogUpdatedAt > 0`);
    }

    return arr.join(' AND ');
  }

  /**
   * Push fighter to the DB. Return new fighter id.
   */
  async save(fighter: Fighter): Promise<string> {
    const dbData = this._convertFighterToDbData(fighter);
    const newDbDataId = this._afDatabase.createPushId();
    if (!newDbDataId) {
      throw new Error('Cannot create new id!');
    }
    await this._afDatabase.list<FighterDbData>('/fighters/').set(newDbDataId, dbData);
    return newDbDataId;
  }

  /**
   * Update fighter or part of fighter using firebase update.
   */
  async update(fighter: Fighter) {
    return this._afDatabase.object(`/fighters/${fighter.id}`).update(this._convertFighterToDbData(fighter));
  }

  async activate(fighterId: string) {
    return this._afDatabase.object(`/fighters/${fighterId}/status`).set(FighterStatus.ACTIVE);
  }

  async delete(fighterId: string) {
    return this._afDatabase.object(`/fighters/${fighterId}/status`).set(FighterStatus.DELETED);
  }

  async getAllByFilter(filterData: FighterFilter, page: number = 0, limit: number = 50): Promise<ListResult<Fighter>> {
    const algoliaParams: algoliasearch.QueryParameters = {
      filters: this._buildAlgoliaQuery(filterData),
      aroundLatLngViaIP: 'true',
      hitsPerPage: limit,
      page
    };
    if (filterData.search) {
      algoliaParams.query = filterData.search;
    }
    this._algoliaService.fightersIndex.clearCache();
    const response = await this._algoliaService.fightersIndex.search(algoliaParams);
    return {
      data: response.hits,
      totalCount: response.nbHits
    };
  }

  getAllByFilterObservable(
    filterDataObservable: Observable<FighterFilter>,
    page: number = 0,
    limit: number = 50
  ): Observable<ListResult<Fighter>> {
    return filterDataObservable.pipe(
      switchMap(filterData => {
        return from(this.getAllByFilter(filterData, page, limit));
      })
    );
  }

  async get(fighterId: string): Promise<Fighter | undefined> {
    return this.getObservable(fighterId).pipe(
      take(1)
    ).toPromise();
  }

  getObservable(fighterId: string): Observable<Fighter | undefined> {
    return this._afDatabase.object(`/fighters/${fighterId}`).snapshotChanges().pipe(
      map((action) => this._convertDbSnapshotToFighter(action.payload))
    );
  }

  async getByFighterUrl(fighterUrl: string): Promise<Fighter | undefined> {
    return this.getByFighterUrlObservable(fighterUrl).pipe(
      take(1)
    ).toPromise();
  }

  getByFighterUrlObservable(fighterUrl: string): Observable<Fighter | undefined> {
    return this._afDatabase.object<UrlFighterData>(`/urls-fighters/${fighterUrl}`).snapshotChanges().pipe(
      map(action => action.payload.val()),
      switchMap(urlFighterData => {
        if (!urlFighterData) {
          return of(undefined);
        }
        return this.getObservable(urlFighterData.fighterId);
      })
    );
  }

  async getFighterUrlsByFighterId(fighterId: string): Promise<string[]> {
    return this.getFighterUrlsByFighterIdObservable(fighterId).pipe(
      take(1)
    ).toPromise();
  }

  getFighterUrlsByFighterIdObservable(fighterId: string): Observable<string[]> {
    return this._afDatabase.list<UrlFighterData>(`/urls-fighters/`, query => query.orderByChild('fighterId').equalTo(fighterId))
      .snapshotChanges()
      .pipe(
        map(action => {
          const urls: string[] = [];
          action.forEach(snapshot => {
            if (snapshot.key) {
              urls.push(snapshot.key);
            }
            return false;
          });
          return urls;
        }),
      );
  }

  getAllAssociations(): Promise<string[]> {
    return this.getAllAssociationsObservable().pipe(
      take(1)
    ).toPromise();
  }

  getAllAssociationsObservable(): Observable<string[]> {
    return this._afDatabase.object<{ [key: string]: number }>(`/globals/associations/`).snapshotChanges().pipe(
      map(action => action.payload.val()),
      map(associationObject => {
        if (!associationObject) {
          return [];
        }
        return Object.keys(associationObject).map(key => decodeURIComponent(key));
      })
    );
  }

  getAllCountries(): Promise<{ code: string, name: string }[]> {
    return this.getAllCountriesObservable().pipe(
      take(1)
    ).toPromise();
  }

  getAllCountriesObservable(): Observable<{ code: string, name: string }[]> {
    return this._afDatabase.object<{ [key: string]: string }>(`/globals/countries/`).snapshotChanges().pipe(
      map(action => action.payload.val()),
      map(countriesObject => {
        if (!countriesObject) {
          return [];
        }
        return Object.keys(countriesObject).map(key => {
          return {
            code: key,
            name: countriesObject[key]
          };
        });
      })
    );
  }
}
