export enum Background {
  MMA = 'mma',
  MUAY_THAI = 'muaythai',
  BOXING = 'boxing',
  WRESTLING = 'wrestling',
  JUDO = 'judo',
  BJJ = 'bjj',
  SAMBO = 'sambo',
  OTHER = 'other'
}

export const BACKGROUNDS = [
  Background.MMA,
  Background.MUAY_THAI,
  Background.BOXING,
  Background.WRESTLING,
  Background.JUDO,
  Background.BJJ,
  Background.SAMBO,
  Background.OTHER
];

export const BACKGROUND_MAP: { [key in Background]: string } = {
  mma: 'MMA',
  muaythai: 'Muay Thai',
  boxing: 'Boxing',
  wrestling: 'Wrestling',
  judo: 'Judo',
  bjj: 'BJJ',
  sambo: 'Sambo',
  other: 'Other'
};
