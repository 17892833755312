import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { ThemePalette } from '@angular/material';
import { UserStatus } from '../../cagehunter-core/models/user';

@Component({
  selector: 'app-user-status-badge',
  template: `
    <mat-chip-list>
      <mat-chip [color]="color" [selected]="!isClosed">{{ status }}</mat-chip>
    </mat-chip-list>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserStatusBadgeComponent implements OnInit, OnChanges {

  @Input() status: UserStatus;

  color: ThemePalette;
  isClosed = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status) {
      if (this.status === UserStatus.ACTIVE) {
        this.color = 'primary';
      } else if (this.status === UserStatus.DELETED) {
        this.color = 'warn';
      }
      this.isClosed = this.status === UserStatus.DISABLED || this.status === UserStatus.INACTIVE || this.status === UserStatus.DRAFT;
    }
  }

}
