import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatTableModule, MatPaginatorModule, MatSortModule, MatToolbarModule, MatButtonModule,
  MatSidenavModule, MatIconModule, MatListModule, MatTooltipModule, MatMenuModule, MatFormFieldModule,
  MatInputModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatChipsModule, MatDialogModule,
  MatProgressSpinnerModule, MatTabsModule, MatCheckboxModule
} from '@angular/material';
import { LayoutModule } from '@angular/cdk/layout';
import { ToastrModule } from 'ngx-toastr';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { LoginDialogComponent } from './dialogs/login-dialog/login-dialog.component';
import { UserStatusBadgeComponent } from './components/user-status-badge.component';
import { FighterStatusBadgeComponent } from './components/fighter-status-badge.component';
import { FighterTableComponent } from './components/fighter-table/fighter-table.component';
import { RouterModule } from '@angular/router';
import { CagehunterModule } from '../cagehunter-core/cagehunter.module';
import { OfferTableComponent } from './components/offer-table/offer-table.component';
import { OfferStatusBadgeComponent } from './components/offer-status-badge.component';

const MATERIAL_MODULES = [
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatTooltipModule,
  MatMenuModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatTabsModule,
];

const CDK_MODULES = [
  LayoutModule
];

const DIALOGS = [
  LoginDialogComponent
];

const COMPONENTS = [
  UserStatusBadgeComponent,
  FighterStatusBadgeComponent,
  FighterTableComponent,
  OfferTableComponent,
  OfferStatusBadgeComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    CagehunterModule,
    RouterModule,
    ...CDK_MODULES,
    ...MATERIAL_MODULES,
  ],
  declarations: [
    LoginDialogComponent,
    ...COMPONENTS,
    FighterTableComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CagehunterModule,
    ToastrModule,
    Ng4LoadingSpinnerModule,
    ...CDK_MODULES,
    ...MATERIAL_MODULES,
    ...COMPONENTS
  ],
  entryComponents: [
    ...DIALOGS
  ]
})
export class SharedModule { }
