import { Injectable, Inject } from '@angular/core';
import * as algoliasearch from 'algoliasearch';

import { AlgoliaClient } from '../cagehunter.module';


@Injectable({
  providedIn: 'root'
})
export class CagehunterAlgoliaService {

  private readonly ALGOLIA_FIGHTERS_INDEX_KEY = 'fighters';
  public readonly ALGOLIA_OFFERS_INDEX_KEY = 'offers';
  private readonly ALGOLIA_USERS_INDEX_KEY = 'users';

  fightersIndex = this.client.initIndex(this.ALGOLIA_FIGHTERS_INDEX_KEY);
  offersIndex = this.client.initIndex(this.ALGOLIA_OFFERS_INDEX_KEY);
  usersIndex = this.client.initIndex(this.ALGOLIA_USERS_INDEX_KEY);

  constructor(
    @Inject(AlgoliaClient) public client: algoliasearch.Client,
  ) {
    this.fightersIndex.setSettings(<any>{
      paginationLimitedTo: 20000,
      ranking: [
        'desc(createdAt)'
      ]
    });
    this.offersIndex.setSettings(<any>{
      paginationLimitedTo: 20000,
      ranking: [
        'desc(createdAt)'
      ]
    });
    this.usersIndex.setSettings(<any>{
      paginationLimitedTo: 20000
    });
  }
}
