import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { CagehunterAuthAdminService } from '../cagehunter-core/services/cagehunter-auth-admin.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild, CanActivate {

  constructor(
    private _adminAuthService: CagehunterAuthAdminService,
    private _toastrService: ToastrService
  ) {

  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (route.data.permission) {
      const hasPermission = this._adminAuthService.hasPermission(route.data.permission);
      if (!hasPermission) {
        this._toastrService.error('You dont have permission to this page');
      }
      return hasPermission;
    }
    return this._adminAuthService.isAuthenticated;
  }
}
