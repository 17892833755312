// taken from wiki so not every used in app
export enum WeightCategory {
  STRAW = 'strawweight',
  FLY = 'flyweight',
  BANTAM = 'bantamweight',
  FEATHER = 'featherweight',
  LIGHT = 'lightweight',
  // SUPER_LIGHT = 'superlightweight',
  WELTER = 'welterweight',
  MIDDLE = 'middleweight',
  // SUPER_MIDDLE = 'supermiddleweight',
  LIGHT_HEAVY = 'lightheavyweight',
  // CRUISER = 'cruiserweight',
  HEAVY = 'heavyweight',
  // SUPER_HEAVY = 'superheavyweight',
}

export const WEIGHTS_MALE = [
  WeightCategory.STRAW,
  WeightCategory.FLY,
  WeightCategory.BANTAM,
  WeightCategory.FEATHER,
  WeightCategory.LIGHT,
  WeightCategory.WELTER,
  WeightCategory.MIDDLE,
  WeightCategory.LIGHT_HEAVY,
  WeightCategory.HEAVY
];

export const WEIGHTS_FEMALE = [
  WeightCategory.STRAW,
  WeightCategory.FLY,
  WeightCategory.BANTAM,
  WeightCategory.FEATHER
];

/**
 * Map enum WeightCategory to label,kg, lbs (conversion is not exact and we have to store it).
 */
export interface WeightMapItem {
  kg: number;
  lbs: number;
  label: string;
}
export const WEIGHT_MAP: { [key in WeightCategory]: WeightMapItem } = {
  strawweight: { kg: 52.2, lbs: 115, label: 'Strawweight' },
  flyweight: { kg: 56.7, lbs: 125, label: 'Flyweight' },
  bantamweight: { kg: 61.2, lbs: 135, label: 'Bantamweight' },
  featherweight: { kg: 65.8, lbs: 145, label: 'Featherweight' },
  lightweight: { kg: 70.3, lbs: 155, label: 'Lightweight' },
  // superlightweight: { kg: 74.8, lbs: 165, label: 'Super lightweight' },
  welterweight: { kg: 77.1, lbs: 170, label: 'Welterweight' },
  middleweight: { kg: 83.9, lbs: 185, label: 'Middleweight' },
  // supermiddleweight: { kg: 88.5, lbs: 195, label: 'Super middleweight' },
  lightheavyweight: { kg: 93.0, lbs: 205, label: 'Light heavyweight' },
  // cruiserweight: { kg: 102.1, lbs: 225, label: 'Cruiserweight' }
  heavyweight: { kg: 120.2, lbs: 265, label: 'Heavyweight' }
};
