import { Pipe, PipeTransform } from '@angular/core';
import * as momentNs from 'moment';

// because of no default export and rollup error we have to assign constructor function to variable
// https://github.com/rollup/rollup/issues/670#issuecomment-281139978
// https://github.com/jvandemo/generator-angular2-library/issues/221
// https://github.com/rollup/rollup/issues/1267
const moment = momentNs;


@Pipe({
  name: 'chAgeFromBirthday',
})
export class AgeFromBirthdayPipe implements PipeTransform {

  transform(value: momentNs.MomentInput): number {
    return moment().diff(value, 'years', false);
  }
}
