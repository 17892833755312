import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { OfferTableDataSource } from './offer-table-datasource';
import { CagehunterOfferService } from 'src/app/cagehunter-core/services/cagehunter-offer.service';
import { OfferStatus, Offer, OfferFilter } from '../../../cagehunter-core/models/offer';
import { CagehunterAuthAdminService } from 'src/app/cagehunter-core/services/cagehunter-auth-admin.service';

@Component({
  selector: 'app-offer-table',
  templateUrl: './offer-table.component.html',
  styleUrls: ['./offer-table.component.scss']
})
export class OfferTableComponent implements OnInit {

  @Input() filterObservable: Observable<OfferFilter>;
  @Input() showOponent = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: OfferTableDataSource;

  OfferStatus = OfferStatus;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [
    'eventName', 'eventDate', 'oponent', 'location', 'radius', 'gender',
    'weightCategory', 'numFights', 'status', 'approved', 'numResponses', 'created', 'actions'
  ];

  constructor(
    private _offerService: CagehunterOfferService,
    private _authSetvice: CagehunterAuthAdminService,
    private _toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.dataSource = new OfferTableDataSource(this.paginator, this.sort, this.filterObservable, this._offerService);
    const index = this.displayedColumns.indexOf('oponent');
    if (index > -1) {
      this.displayedColumns.splice(index, 1);
    }
  }

  async closeOffer(offer: Offer) {
    if (offer.id) {
      await this._offerService.close(offer.id);
      this._toastrService.success('Offer closed');
      offer.status = OfferStatus.CLOSED;
    }
  }

  async approveOffer(offer: Offer) {
    if (offer.id && this._authSetvice.currentLoggedAdminUser) {
      await this._offerService.approve(offer.id, this._authSetvice.currentLoggedAdminUser.email);
      this._toastrService.success('Offer approved');
      offer.approvedAt = new Date();
      offer.approvedByEmail = this._authSetvice.currentLoggedAdminUser.email;
    }
  }

}
