import { ImageObject } from './image';

export enum AdminPermission {
  SuperAdmin = 'superadmin',
  RunScripts = 'runScripts',
  EditFighterUrls = 'editFighterUrls',
  EditCoupons = 'editCoupons',
}

/**
 * Represents data from DB under /admins/<user_id>
 */
export interface AdminData {
  /** Comma separated string with permissions e.g. 'editCoupons,superadmin,editWhatever' */
  permissions: string;
}

/**
 * Special type of user used only in admin.
 */
export interface AdminUser {
  email: string;
  permissions: AdminPermission[];
}

export interface UserFilter {
  search?: string;
  subscriptionType?: SubscriptionType;
  professionType?: ProfessionType;
  status?: UserStatus;
}

export interface UserData {
  id?: string;
  profession?: ProfessionType;
  email?: string;
  firstName?: string;
  lastName?: string;
  // matchmaker only
  organization?: string;
  // manager only
  managementName?: string;

  images?: ImageObject;

  providerId?: string;
  // social only
  accessToken?: string;
  socialImage?: string;

  // notifications
  fcmTokens?: { [key: string]: boolean };
  roles?: {
    [key: string]: boolean
  };

  numMessages?: number;
  numNewMessages?: number;

  numOffers?: number;
  numNewOffers?: number;

  numNotifications?: number;
  numNewNotifications?: number;

  // only if fighter with login got a request from manager to by managed by him
  requestsFromManagers?: {
    [key: string]: {
      [key: string]: {
        //      createdAt: Date
        status: RequestFromManagersStatus,
        text: string
      }
    }
  };

  // units settings
  units?: 'imperial' | 'metric';


  subscriptionStatus?: SubscriptionStatus;
  subscriptionType?: SubscriptionType;

  stripeCustomerId?: string;
  stripeCustomer?: any;
  stripeSubscriptionId?: string;
  stripeSubscription?: any;
  stripeSource?: any;
  stripeInvoices?: any;

  fighterSlotsFree?: number;
  fighterSlotsPaid?: number;

  profileImages?: ImageObject;

  activeLinkedUser?: string;
  linkedUsers?: {
    [key: string]: boolean
  };

  status?: UserStatus;

}

export enum UserStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DISABLED = 'disabled',
  DELETED = 'deleted'
}

export enum SubscriptionType {
  FREE = 'free',
  PAID = 'paid',
}

// this statuses are not same as stripe subscription statuses
export enum SubscriptionStatus {
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PASTDUE = 'pastdue',
  CANCELED = 'canceled',
  PENDINGCANCELLATION = 'pendingcancellation'
}

export enum ProfessionType {
  FIGHTER = 'fighter',
  SHADOWFIGHTER = 'shadowfighter',
  MANAGER = 'manager',
  MATCHMAKER = 'matchmaker'
}

export enum RequestFromManagersStatus {
  CREATED = 'created',
  SEEN = 'seen',
  DECLINED = 'declined',
  ACCEPTED = 'accepted'
}

export enum Role {
  ADMIN = 'admin'
}
